import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import './SetNewPasswordForm.css'
import {
	SET_NEW_PASSWORD_LABEL,
	SET_NEW_PASSWORD_DATE_OF_BIRTH,
	SET_NEW_PASSWORD_EMAIL,
	SET_NEW_PASSWORD_EMAIL_PLACEHOLDER,
	SET_NEW_PASSWORD_HEADER,
	SETUP_PASSWORD_TEXT
} from 'utils/messages'
import Loader from 'components/global/Loader/Loader'
import {ButtonTypes, ButtonSizes, ButtonLabels} from 'utils/constants'
import { Button } from 'components/buttons/Button/Button'
import { FormPassword } from 'components/formFields/FormPassword/FormPassword'
import {RESET_NEW_PASSWORD} from 'common/endpoints'
import fetchApi from 'common/fetchApi'
import { FormDatePicker2 } from 'components/formFields/FormDatePicker2/FormDatePicker2'
import { FormInput2 } from 'components/formFields/FormInput2/FormInput2'
import {navigate} from 'gatsby';
import {prepareDate} from 'utils/helpers/format.helper';

export const SetNewPasswordForm = ({ location }) => {
	const { handleSubmit, register, control } = useForm()
	const [isLoading, setIsLoading] = useState()
	const [checks, setChecks] = useState()
	const [errors, setErrors] = useState()
	const [password, setPassword] = useState('')
	const [passwordQuery, setPasswordQuery] = useState('')
	const [token, setToken] = useState()

	useEffect(() => {
		const urlParams = new URLSearchParams(location?.search)
		const tokenParameter = urlParams?.get('token')

		if (tokenParameter) {
			setToken(tokenParameter)
		}
	}, [])

	useEffect(() => {
		const timeOutId = setTimeout(
			() => setPassword(passwordQuery),
			500
		)
		return () => clearTimeout(timeOutId)
	}, [passwordQuery])

	const onSubmit = async (data) => {
		setIsLoading(true)
		try {
			await fetchApi(RESET_NEW_PASSWORD, {
				method: 'POST',
				data: {
					email: data.email,
					date_of_birth: prepareDate(data.date_of_birth),
					token,
					password,
				},
			})
			await navigate('/app/login');
		} catch (error) {
			const [{ checks: respondedChecks, fields: fieldsErrors }] = error?.response?.data
			setChecks(respondedChecks)
			setErrors(fieldsErrors?.[0])
		} finally {
			setIsLoading(false)
		}
	}

	if (isLoading) {
		return (
			<div className="set-new-password-loader-container">
				<Loader />
			</div>
		)
	}

	return (
		<form className="set-new-password-form">
			<div className="set-new-password-header">{SET_NEW_PASSWORD_HEADER}</div>
			<div className="set-new-password">
				<FormInput2
					label={SET_NEW_PASSWORD_EMAIL}
					isRequired
					register={register}
					fieldErrors={errors?.email}
					fieldName="email"
					type="email"
					placeholder={SET_NEW_PASSWORD_EMAIL_PLACEHOLDER}
					dataTestId="set-new-password-email"
				/>
				<FormDatePicker2
					label={SET_NEW_PASSWORD_DATE_OF_BIRTH}
					isRequired
					control={control}
					register={register}
					fieldErrors={errors?.date_of_birth}
					fieldName="date_of_birth"
					dataTestId="set-new-password-date-of-birth"
				/>
				<FormPassword
					label={SET_NEW_PASSWORD_LABEL}
					fieldName="new_password"
					onChange={(e) => setPasswordQuery(e)}
					register={register}
					dataTestId="set-new-password-input"
				/>
			</div>
			<div className={!passwordQuery && 'set-new-password-criteria-container'}>
				{checks && <div className="set-new-password-text">{SETUP_PASSWORD_TEXT}</div>}
				{checks?.map((item) => {
					return (
						<div className="set-new-password-criteria" key={item.name}>
							<div
								className={`set-new-password-criteria-status ${item.status}`}
							/>
							<div className="set-new-password-criteria-label">{item.name}</div>
						</div>
					)
				})}
			</div>
			<div className="set-new-password-setup-button">
				<Button
					handleOnClick={handleSubmit(onSubmit)}
					type={ButtonTypes.Primary}
					size={ButtonSizes.Large}
					label={ButtonLabels.ResetPassword}
					dataTestId="set-new-password-button"
				/>
			</div>
		</form>
	)
}
