import React from 'react'
import LoginPage from "components/container/LoginPage/LoginPage";
import {ResetPasswordForm} from "components/forms/ResetPasswordForm/ResetPasswordForm";
import { SetNewPasswordForm } from 'components/forms/SetNewPassword/SetNewPasswordForm'

const ResetPassword = ({ location }) => {
    const urlParams = new URLSearchParams(location?.search)
    const withToken = urlParams?.get('token');
    const FormComponent = withToken ? SetNewPasswordForm : ResetPasswordForm;

    return (
        <LoginPage Form={FormComponent} location={location}/>
    )
}
export default ResetPassword;
