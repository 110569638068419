import React, {useRef, useState} from 'react';
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha"
import './ResetPasswordForm.css';
import {
    LOGIN_EMAIL,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_HEADER,
    RESET_PASSWORD_TEXT,
    LOGIN_EMAIL_PLACEHOLDER
} from "utils/messages";
import {Button} from "components/buttons/Button/Button";
import {ButtonLabels, ButtonSizes, ButtonTypes, FetchMethods} from "utils/constants";
import {navigate} from "gatsby";
import {authErrors} from "store/selectors/selectors";
import Loader from "components/global/Loader/Loader";
import fetchApi from "common/fetchApi";
import {RESET_PASSWORD} from "common/endpoints";
import {ErrorMessage} from "components/global/ErrorMessage/ErrorMessage";
import FormInput from "components/formFields/FormInput";

export const ResetPasswordForm = ({ location }) => {
    const [isLoading, setIsLoading] = useState();
    const [showError, setShowError] = useState(false);
    const { handleSubmit, register, setValue } = useForm();
    const captchaRef = useRef(null)
    const urlParams = new URLSearchParams(location?.search);
    const token = urlParams?.get('token');
    const isRecaptchaActive = !process.env.GATSBY_DISABLE_RECAPTCHA;

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            await fetchApi(RESET_PASSWORD, {
                method: FetchMethods.Post,
                data: { email: data.email, recaptcha: isRecaptchaActive ? data.recaptcha: '', token }
            });

            isRecaptchaActive && captchaRef?.current?.reset();
            setIsLoading(false);

            await navigate('/app/resetPasswordSuccess/');
        } catch {
            setShowError(true);
            isRecaptchaActive && captchaRef?.current?.reset();
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return (
            <div className='login-loader-container'>
                <Loader/>
            </div>
        );
    }

    return (
        <form className='reset-password-form'>
            <div className='reset-password-header'>{RESET_PASSWORD_HEADER}</div>
            {showError && <ErrorMessage message={RESET_PASSWORD_ERROR} />}
            <div className='reset-password-text'>{RESET_PASSWORD_TEXT}</div>
            <FormInput
                label={LOGIN_EMAIL}
                isRequired
                register={register}
                fieldName='email'
                type='email'
                placeholder={LOGIN_EMAIL_PLACEHOLDER}
                errorsSelector={authErrors}
            />
            {
                isRecaptchaActive && <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.GATSBY_RECAPTCHA_PUBLIC_KEY}
                    className='reset-password-recaptcha'
                    onChange={value => {
                        setValue('recaptcha', value)
                    }}
                />
            }
            <div className='reset-password-buttons'>
                <div className='reset-password-back'>
                    <Button
                        handleOnClick={(event) => {
                            event.preventDefault()
                            navigate('/app/login')
                        }}
                        type={ButtonTypes.Secondary}
                        size={ButtonSizes.Large}
                        label={ButtonLabels.Back}
                        dataTestId='reset-password-back-button'
                    />
                </div>
                <Button
                    handleOnClick={handleSubmit(onSubmit)}
                    type={ButtonTypes.Primary}
                    size={ButtonSizes.Large}
                    label={ButtonLabels.RecoveryLink}
                    dataTestId='reset-password-submit-button'
                />
            </div>
        </form>
    )
}